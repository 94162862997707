import { get, post, postProgress } from './http'

// 项目列表
export const projectList = data => get(`/v1/project/list`, data)

// 获得项目状态列表
export const getProjectStatus = data => get(`/v1/project/status`, data)

// 获得合同状态
export const getContractState = data => get(`/v1/project/contractState`, data)

// 获得项目类型
export const getProjectType = data => get(`/v1/project/type`, data)

// 获得项目角色列表
export const getProjectRoleList = data => get(`/v1/project/role/list`, data)

// 项目角色保存
export const getProjectRoleSave = data => post(`/v1/project/role/save`, data)

// 项目保存(合同信息)
export const saveFinance = data => post(`/v1/project/saveFinance`, data)

// 角色部门
export const getDepartmentList = data => get(`/v1/project/role/department`, data)

// 角色状态操作
export const setProjectRoleStatus = data => post(`/v1/project/role/status`, data)

// 获取审批流程列表
export const getProjectWorkflowList = data => get(`/v1/project/workflow/list`, data)

// 流程状态操作
export const setProjectWorkflowStatus = data => post(`/v1/project/workflow/status`, data)

// 流程保存
export const saveProjectWorkflow = data => post(`/v1/project/workflow/save`, data)

// 审批流程详细信息
export const getProjectWorkflow = data => get(`/v1/project/workflow/detail`, data)

// 里程碑模板列表
export const getMilestoneList = data => get(`/v1/project/milestone/list`, data)

// 新建里程碑模板
export const createMilestone = data => post(`/v1/project/milestone/save`, data)

// 修改里程碑模板状态
export const changeStatusByMilestone = data => post(`/v1/project/milestone/status`, data)

// 里程碑模板阶段列表
export const getStageByMilestone = data => get(`/v1/project/milestone/stage`, data)

// 获取里程碑模版详细内容列表
export const getDetailListByMilestone = data => get(`/v1/project/milestone/detailList`, data)

// 里程碑模板创建里程碑
export const createDetailByMilestone = data => post(`/v1/project/milestone/detailSave`, data)

// 里程碑模板删除里程碑
export const deleteDetailByMilestone = data => post(`/v1/project/milestone/detailDelete`, data)

// 里程碑模板的里程碑排序保存
export const detailSaveOrder = data => post(`/v1/project/milestone/detailSaveOrder`, data)

// 获取项目Oracle里程碑版本
export const oracleMilestonesVersion = data => get(`/v1/project/milestone/oracleMilestonesVersion`, data)

// 获取项目Oracle同步里程碑
export const oracleMilestones = data => get(`/v1/project/milestone/oracleMilestones`, data)

// 获取项目合同付款节点列表（财务BP）
export const contractMilestones = data => get(`/v1/project/milestone/contractMilestones`, data)

// 项目合同付款节点保存（财务BP）
export const contractMilestonesSave = data => post(`/v1/project/milestone/contractMilestonesSave`, data)

// 项目合同付款节点保存（财务BP）--实际回款,开票信息
export const contractMilestonesSave2 = data => post(`/v1/project/milestone/contractMilestonesSave2`, data)

// 项目合同付款节点附件下载
export const attachmentDownloadMel = (data, onDownloadProgress, cancelToken) => postProgress(`/v1/project/milestone/attachmentDownload`, data, onDownloadProgress, cancelToken, true)

// 项目回款计划列表（商务BD）
export const payPlanMilestones = data => get(`/v1/project/milestone/payPlanMilestones`, data)

// 项目回款计划保存（商务BD）
export const payPlanMilestonesSave = data => post(`/v1/project/milestone/payPlanMilestonesSave`, data)

// 项目管理-项目配置-中心设置 根据项目id获取中心信息列表
export const getHospital = data => get(`/v1/project/config/hospital`, data)

// 项目管理-项目配置-中心设置 中心状态枚举
export const getHospitalStatus = data => get(`/v1/project/config/hospitalStatus`, data)

// 项目管理-项目配置-中心设置 中心信息保存
export const hospitalSave = data => post(`/v1/project/config/hospitalSave`, data)

// 项目管理-项目配置-访视配置列表
export const getVisitList = data => get(`/v1/project/config/visit/list`, data)

// 项目管理-项目配置-访视配置保存
export const visitSave = data => post(`/v1/project/config/visit/save`, data)

// 项目管理-项目配置-访视删除
export const deleteVisit = data => post(`/v1/project/config/visit/delete`, data)

// 项目管理-项目配置-项目成员 根据项目id获取项目成员列表
export const getMember = data => get(`/v1/project/config/members`, data)

// 项目管理-项目配置-项目成员 根据项目id和用户id，回显用户负责的中心列表
export const getMemberHospital = data => get(`/v1/project/config/memberHospital`, data)

// 项目管理-项目配置-项目成员 根据项目id和用户id关联中心
export const memberHospitalSave = data => post(`/v1/project/config/memberHospitalSave`, data)

// 项目管理-项目配置-项目成员 项目成员保存
export const memberSave = data => post(`/v1/project/config/memberSave`, data)

// 里程碑计划
export const planMilestones = data => get(`/v1/project/milestone/planMilestones`, data)

// 里程碑计划保存
export const planMilestonesSave = data => post(`/v1/project/milestone/planMilestonesSave`, data)

// 里程碑计划调整保存
export const planMilestonesReviseSave = data => post(`/v1/project/milestone/planMilestonesReviseSave`, data)

// 里程碑计划删除
export const planMilestonesDelete = data => post(`/v1/project/milestone/planMilestonesDelete`, data)

// 项目管理-项目配置-项目里程碑 根据项目id获取项目里程碑列表
export const getMilestone = data => get(`/v1/project/config/milestone`, data)

// 项目管理-项目配置-项目里程碑 项目里程碑计划列表
export const getMilestonePlanList = data => get(`/v1/project/config/milestonePlanList`, data)

// 项目管理-项目配置-项目里程碑 项目里程碑历史调整信息
export const getMilestoneReviseHistory = data => get(`/v1/project/config/milestoneReviseHistory`, data)

// 项目管理-项目配置-项目里程碑 项目里程碑保存
export const milestoneSave = data => post(`/v1/project/config/milestoneSave`, data)

// 项目管理-项目配置-项目里程碑 获取项目里程碑详细信息
export const getMilestoneDetail = data => get(`/v1/project/config/milestoneDetail`, data)

// 项目管理-项目配置-项目里程碑 项目里程碑详细信息保存
export const milestoneDetailSave = data => post(`/v1/project/config/milestoneDetailSave`, data)

// 项目管理-项目配置-项目里程碑 项目里程碑调整计划保存
export const milestoneReviseSave = data => post(`/v1/project/config/milestoneReviseSave`, data)

// 项目里程碑计划列表导出
export const milestonePlanListExport = data => postProgress(`/v1/project/config/milestonePlanListExport`, data)

// 项目管理-项目配置-中心里程 根据项目id获取项目中心里程碑列表
export const getMilestoneHospital = data => get(`/v1/project/config/milestoneHospital`, data)

// 项目管理-项目配置-中心里程 根据项目id获取项目中心里程碑列表
export const milestoneHospitalSave = data => post(`/v1/project/config/milestoneHospitalSave`, data)

// 项目管理-项目配置-中心里程 中心里程碑详细信息保存
export const milestoneHospitalDetailSave = data => post(`/v1/project/config/milestoneHospitalDetailSave`, data)

// 项目管理-项目配置-中心里程 中心里程碑详细信息删除
export const milestoneHospitalDetailDelete = data => post(`/v1/project/config/milestoneHospitalDetailDelete`, data)

// 项目管理-项目配置-项目审批 获取审批类型
export const approvalType = data => get(`/v1/project/config/approvalType`, data)

// 项目管理-项目配置-项目审批 根据项目id获取审批列表
export const approvalProcess = data => get(`/v1/project/config/approvalProcess`, data)

// 项目管理-项目配置-项目审批 项目审批保存
export const approvalProcessSave = data => post(`/v1/project/config/approvalProcessSave`, data)

// 项目管理-项目-项目概览 项目概览
export const overview = data => get(`/v1/project/data/overview`, data)

// 项目管理-项目-项目概览  项目入组数据（按月份）
export const patientMonth = data => get(`/v1/project/data/patientMonth`, data)

// 项目管理-中心-中心概览 基础数据
export const centerOverview = data => get(`/v1/project/hospital/overview`, data)

// 项目管理-中心-中心概览 中心入组数据（按月份）
export const centerPatientMonth = data => get(`/v1/project/hospital/patientMonth`, data)

// 项目管理-中心-中心里程碑 获取中心里程碑详细信息
export const milestoneHospitalDetailById = data => get(`/v1/project/config/milestoneHospitalDetailById`, data)

// 项目管理-中心-中心里程碑 中心里程碑详细信息更新
export const milestoneHospitalUpdate = data => post(`/v1/project/config/milestoneHospitalUpdate`, data)

// 项目管理-中心-中心里程碑 中心里程碑调整计划保存
export const milestoneHospitalReviseUpdate = data => post(`/v1/project/config/milestoneHospitalReviseUpdate`, data)

// 项目管理-中心-中心里程碑 获取中心里程碑调整记录详细信息
export const milestoneReviseDetail = data => get(`/v1/project/config/milestoneReviseDetail`, data)

// 项目管理-中心-受试者 获取中心受试者列表
export const patientlist = data => get(`/v1/project/patient/list`, data)

// 项目管理-中心-受试者 获取中心受试者列表
export const patientsave = data => post(`/v1/project/patient/save`, data)

// 项目管理-中心-受试者 获取中心受试者详细信息
export const patientinfo = data => get(`/v1/project/patient/info`, data)

// 项目管理-中心-受试者 受试者删除
export const patientdelete = data => post(`/v1/project/patient/delete`, data)

// 项目管理-中心-受试者 受试者访视日历
export const patientvisit = data => get(`/v1/project/patient/visit`, data)

// 项目管理-中心-受试者 获取中心受试者ICF列表
export const ICFList = data => get(`/v1/project/patient/ICFList`, data)

// 项目管理-中心-受试者 获取中心受试者ICF列表
export const ICFSave = data => post(`/v1/project/patient/ICFSave`, data)

// 项目管理-中心-受试者 受试者ICF删除
export const ICFDelete = data => post(`/v1/project/patient/ICFDelete`, data)

// 项目管理-中心-sae SAE报告类型
export const saeReportType = data => get(`/v1/project/patient/saeReportType`, data)

// 项目管理-中心-sae SAE报告与研究关系
export const saeDoctorReason = data => get(`/v1/project/patient/saeDoctorReason`, data)

// 项目管理-中心-sae 获取中心受试者SAE列表
export const SAEList = data => get(`/v1/project/patient/SAEList`, data)

// 项目管理-中心-sae 受试者SAE(严重不良事件)保存
export const SAESave = data => post(`/v1/project/patient/SAESave`, data)

// 项目管理-中心-sae 受试者SAE删除
export const SAEDelete = data => post(`/v1/project/patient/SAEDelete`, data)

// 项目管理-中心-监查问题 问题来源
export const questionsource = data => get(`/v1/project/hospital/question/source`, data)

// 项目管理-中心-监查问题 监查类型
export const questiontype = data => get(`/v1/project/hospital/question/type`, data)

// 项目管理-中心-监查问题 问题分类
export const questionclass = data => get(`/v1/project/hospital/question/class`, data)

// 项目管理-中心-监查问题 获取中心监查问题列表
export const questionlist = data => get(`/v1/project/hospital/question/list`, data)

// 项目管理-中心-监查问题 监查问题保存
export const questionsave = data => post(`/v1/project/hospital/question/save`, data)

// 项目管理-中心-监查问题 获取中心监查问题
export const questiondetail = data => get(`/v1/project/hospital/question/detail`, data)

// 项目管理-中心-监查问题 监查问题删除
export const questiondelete = data => post(`/v1/project/hospital/question/delete`, data)

//项目管理-中心-sae 下载sae附件
export const SAEDownload = (data, onDownloadProgress, cancelToken) => postProgress(`/v1/project/patient/SAEDownload`, data, onDownloadProgress, cancelToken, true)

// 项目管理-中心-监查问题 监查问题状态修改
export const questionstatus = data => post(`/v1/project/hospital/question/status`, data)

// 项目管理-项目-联系人 申办方身份
export const projectIdentity = data => get(`/v1/project/contacts/projectIdentity`, data)

// 项目管理-项目-联系人 获取申办方人员列表
export const projectContactsList = data => get(`/v1/project/contacts/projectContactsList`, data)

// 项目管理-项目-联系人 申办方人员保存
export const projectContactsSave = data => post(`/v1/project/contacts/projectContactsSave`, data)

// 项目管理-项目-联系人 申办方人员状态修改
export const projectContactsStatus = data => post(`/v1/project/contacts/projectContactsStatus`, data)

// 项目管理-中心-联系人 中心人员角色
export const projectHospitalIdentity = data => get(`/v1/project/hospital/contacts/projectHospitalIdentity`, data)

// 项目管理-中心-联系人 获取中心人员列表
export const projectHospitalContactsList = data => get(`/v1/project/hospital/contacts/projectHospitalContactsList`, data)

// 项目管理-中心-联系人 中心人员保存
export const projectHospitalContactsSave = data => post(`/v1/project/hospital/contacts/projectHospitalContactsSave`, data)

// 项目管理-中心-联系人 申办方人员状态修改
export const projectHospitalContactsStatus = data => post(`/v1/project/hospital/contacts/projectHospitalContactsStatus`, data)

// 项目管理-中心-方案违背pd PD范围
export const pdrange = data => get(`/v1/project/hospital/pd/range`, data)

// 项目管理-中心-方案违背pd PD类型
export const pdtype = data => get(`/v1/project/hospital/pd/type`, data)

// 项目管理-中心-方案违背pd PD等级
export const pdlevel = data => get(`/v1/project/hospital/pd/level`, data)

// 项目管理-中心-方案违背pd 获取中心PD列表
export const pdlist = data => get(`/v1/project/hospital/pd/list`, data)

// 项目管理-中心-方案违背pd PD保存
export const pdsave = data => post(`/v1/project/hospital/pd/save`, data)

// 项目管理-中心-方案违背pd 获取PD详细信息
export const pddetail = data => get(`/v1/project/hospital/pd/detail`, data)

// 项目管理-中心-方案违背pd PD删除
export const pddelete = data => post(`/v1/project/hospital/pd/delete`, data)

// 项目管理-中心-方案违背pd PD提交审批时：项目id获取审批详细信息
export const approvalProcessDetail = data => get(`/v1/project/config/approvalProcessDetail`, data)

// 项目管理-中心-方案违背pd PD提交审批
export const approvalSubmit = data => post(`/v1/project/hospital/pd/approvalSubmit`, data)

// 项目管理-中心-方案违背pd PD类型—子类
export const typeChild = data => get(`/v1/project/hospital/pd/typeChild`, data)

// 项目管理-中心-方案违背pd PD导出
export const pdexport = (data, onDownloadProgress, cancelToken) => postProgress(`/v1/project/hospital/pd/export`, data, onDownloadProgress, cancelToken, true)

// 项目管理-中心-方案违背pd 获取PD审批流程
export const pdapprovalPDDetail = data => get(`/v1/project/hospital/pd/approvalPDDetail`, data)

// 项目管理-项目-方案违背 获取PD审批列表
export const approvalPDList = data => get(`/v1/project/pd/approvalPDList`, data)

// 项目管理-项目-方案违背 PD审批
export const pdapproval = data => post(`/v1/project/pd/approval`, data)

// 项目管理-中心-监查计划 监查计划类型
export const hospitalplantype = data => get(`/v1/project/hospital/plan/type`, data)

// 项目管理-中心-监查计划 计划监查内容
export const hospitalplancontent = data => get(`/v1/project/hospital/plan/content`, data)

// 项目管理-中心-监查计划 获取中心监查计划列表
export const hospitalplanlist = data => get(`/v1/project/hospital/plan/list`, data)

// 项目管理-项目配置-项目成员 根据项目id中心id职位编码获取项目成员列表
export const projectconfigmembersList = data => get(`/v1/project/config/membersList`, data)

// 项目管理-中心-监查计划 PD保存
export const hospitalplansave = data => post(`/v1/project/hospital/plan/save`, data)

// 项目管理-中心-监查计划 获取PD详细信息
export const hospitalplandetail = data => get(`/v1/project/hospital/plan/detail`, data)

// 项目管理-中心-监查计划 监查计划删除
export const hospitalplandelete = data => post(`/v1/project/hospital/plan/delete`, data)

// 项目管理-中心-监查计划 监查计划导出
export const hospitalplanexport = (data, onDownloadProgress, cancelToken) => postProgress(`/v1/project/hospital/plan/export`, data, onDownloadProgress, cancelToken, true)

// 项目管理-中心-监查计划 监查计划提交审批时：项目id获取审批详细信息
export const projectconfigapprovalProcessDetail = data => get(`/v1/project/config/approvalProcessDetail`, data)

// 项目管理-中心-监查计划 监查计划提交审批
export const hospitalplanapprovalSubmit = data => post(`/v1/project/hospital/plan/approvalSubmit`, data)

// 项目管理-项目-监查计划审批 获取监查计划审批列表
export const projectplanapprovalList = data => get(`/v1/project/plan/approvalList`, data)

// 项目管理-项目-监查计划审批 监查计划审批
export const projectplanapproval = data => post(`/v1/project/plan/approval`, data)

// 项目管理-项目-监查计划审批 获取检查计划审批情况
export const hospitalplanapprovalDetail = data => get(`/v1/project/hospital/plan/approvalDetail`, data)

// 项目管理-项目-监查报告审批 获取检查计划审批情况
export const hospitalplanReportDetail = data => get(`v1/project/hospital/plan/report/approvalDetail`, data)

// 项目管理-系统配置-报告模板 报告模版类型
export const reportTemplatetemplateType = data => get(`/v1/project/reportTemplate/templateType`, data)

// 项目管理-系统配置-报告模板 报告模版监查类型
export const reportTemplatetype = data => get(`/v1/project/reportTemplate/type`, data)

// 项目管理-系统配置-报告模板 监查报告—类型（仅监查报告模版使用）
export const reportTemplatemonitoringReportType = data => get(`/v1/project/reportTemplate/monitoringReportType`, data)

// 项目管理-系统配置-报告模板 获取报告模板列表
export const reportTemplatelist = data => get(`/v1/project/reportTemplate/list`, data)

// 项目管理-系统配置-报告模板 报告模板保存
export const reportTemplatesave = data => post(`/v1/project/reportTemplate/save`, data)

// 项目管理-系统配置-报告模板 获取报告模板详细信息
export const reportTemplatedetail = data => get(`/v1/project/reportTemplate/detail`, data)

// 项目管理-系统配置-报告模板 报告模板删除
export const reportTemplatedelete = data => post(`/v1/project/reportTemplate/delete`, data)

// 项目管理-中心-监查计划 下载报告模板文件
export const reportTemplatedownload = (data, onDownloadProgress, cancelToken) => postProgress(`/v1/project/reportTemplate/download`, data, onDownloadProgress, cancelToken, true)

// 下载WBS报价表模板
export const reportworkHoursTemplatedownload = data => get(`/v1/project/workHours/template`, data)

// 项目管理-系统配置-报告模板 保存模版内容
export const saveTemplate = data => post(`/v1/project/reportTemplate/saveTemplate`, data)

// 项目管理-中心-监查计划 确认信表单保存
export const reportSave = data => post(`/v1/project/hospital/plan/reportSave`, data)

// 项目管理-中心-监查计划 确认信详细信息
export const reportDetail = data => get(`/v1/project/hospital/plan/reportDetail`, data)

// 项目管理-系统配置-邮件服务 邮件列表
export const mailList = data => get(`/v1/project/config/mailList`, data)

// 项目管理-系统配置-邮件服务 邮件服务保存
export const mailSave = data => post(`/v1/project/config/mailSave`, data)

// 项目管理-系统配置-邮件服务 获取邮件服务详细信息
export const mailDetail = data => get(`/v1/project/config/mailDetail`, data)

// 项目管理-系统配置-邮件服务 邮件服务删除
export const mailDelete = data => post(`/v1/project/config/mailDelete`, data)

// 项目管理-系统配置-邮件服务 邮件服务验证
export const mailCheck = data => post(`/v1/project/config/mailCheck`, data)

// 项目管理-报告管理-监查计划 确认信下载
export const reportDownload = (data, onDownloadProgress, cancelToken) => postProgress(`/v1/project/hospital/plan/reportDownload`, data, onDownloadProgress, cancelToken, true)

// 项目管理-中心-报告管理 获取中心监查报告列表
export const hospitalreportlist = data => get(`/v1/project/hospital/report/list`, data)

// 项目管理-中心-监查计划 执行信息保存
export const executeInfoSave = data => post(`/v1/project/hospital/plan/executeInfoSave`, data)

// 项目管理-中心-联系报告 获取中心联系报告列表
export const reportlist = data => get(`/v1/project/hospital/contacts/report/list`, data)

// 项目管理-中心-联系报告 获取中心联系报告列表
export const reportsave = data => post(`/v1/project/hospital/contacts/report/save`, data)

// 项目管理-中心-联系报告 获取单个联系报告详细信息
export const reportdetail = data => get(`/v1/project/hospital/contacts/report/detail`, data)

// 项目管理-中心-联系报告 联系报告删除
export const reportdelete = data => post(`/v1/project/hospital/contacts/report/delete`, data)

// 项目管理-中心-联系报告 联系报告导出
export const reportexport = (data, onDownloadProgress, cancelToken) => postProgress(`/v1/project/hospital/contacts/report/export`, data, onDownloadProgress, cancelToken, true)

// 项目管理-中心-联系报告 联系报告提交审批
export const reportapprovalSubmit = data => post(`/v1/project/hospital/contacts/report/approvalSubmit`, data)

// 项目管理-中心-联系报告 联系报告删除
export const reportapprovalDetail = data => get(`/v1/project/hospital/contacts/report/approvalDetail`, data)

// 项目管理-项目-联系报告 获取联系报告审批列表
export const approvalList = data => get(`/v1/project/contacts/report/approvalList`, data)

// 项目管理-项目-联系报告 联系报告审批
export const approval = data => post(`/v1/project/contacts/report/approval`, data)

// 项目管理-中心-监查计划 确认信生成报告（word版本）
export const createReport = data => post(`/v1/project/hospital/plan/createReport`, data)

// 项目管理-中心-监查计划 确认信生成报告（PDF版本）
export const createReportPDF = data => post(`/v1/project/hospital/plan/createReportPDF`, data)

// 项目管理-中心-监察报告 攥写监察报告信息
export const reportInfo = data => get(`/v1/project/hospital/plan/reportInfo`, data)

// 项目管理-中心-监察报告 发送邮件保存
export const sendMailSave = data => post(`/v1/project/hospital/plan/sendMailSave`, data)

// 项目管理-中心-监察报告 报告发送邮件列表
export const sendMailList = data => get(`/v1/project/hospital/plan/sendMailList`, data)

// 项目管理-中心-监察报告 提交审批
export const planReportApprovalSubmit = data => post(`/v1/project/hospital/plan/report/approvalSubmit`, data)

// 项目管理-项目-监查报告 监查报告审批
export const planreportApprovalList = data => get(`/v1/project/plan/report/approvalList`, data)

// 项目管理-项目-监察报告 提交审批
export const planReportApproval = data => post(`/v1/project/plan/report/approval`, data)

// 项目管理-项目-痕迹 项目痕迹
export const operationRecords = data => get(`/v1/project/operationRecords`, data)

// 项目管理-项目-我的项目 项目信息保存
export const save = data => post(`/v1/project/save`, data)

// 项目管理-中心-监查报告 监查报告字段批注获取
export const annotationsList = data => get(`/v1/project/hospital/plan/report/annotationsList`, data)

// 项目管理-中心-监查报告 监查报告字段批注批注保存
export const annotationsSave = data => post(`/v1/project/hospital/plan/report/annotationsSave`, data)

// 项目管理-中心-受试者SAE SAE编码列表
export const SAENumberList = data => get(`/v1/project/patient/SAENumberList`, data)

// 项目管理-中心-受试者SAE SAE情况分类
export const saeReportClass = data => get(`/v1/project/patient/saeReportClass`, data)

// 项目管理-中心-受试者SAE SAE用药采取措施
export const saeReportMeasure = data => get(`/v1/project/patient/saeReportMeasure`, data)

// 项目管理-中心-受试者SAE SAE转归
export const saeReportReturn = data => get(`/v1/project/patient/saeReportReturn`, data)

// 项目管理-中心-受试者列表 受试者退出原因
export const exitReason = data => get(`/v1/project/patient/exitReason`, data)

// 项目管理-中心-监查计划 确认信和随访信邮件收件人列表
export const emailContactsList = data => get(`/v1/project/contacts/emailContactsList`, data)

// 项目管理-中心-方案违背 方案违背撤回
export const approvalRevoke = data => post(`/v1/project/hospital/pd/approvalRevoke`, data)

// 项目管理-中心-监查计划 监查计划撤回
export const planApprovalRevoke = data => post(`/v1/project/hospital/plan/approvalRevoke`, data)

// 项目管理-中心-监察报告 提交审批撤回
export const planReportApprovalRevoke = data => post(`/v1/project/hospital/plan/report/approvalRevoke`, data)

// 项目管理-里程碑-里程碑模板 里程碑模版任务保存
export const milestoneTaskSave = data => post(`/v1/project/milestone/taskSave`, data)

// 里程碑模版任务删除
export const milestoneTaskDelete = data => post(`/v1/project/milestone/taskDelete`, data)

// 里程碑模版任务列表
export const getMilestoneTaskList = data => get(`/v1/project/milestone/taskList`, data)

// 项目管理-里程碑-里程碑模板 项目里程碑排序保存
export const milestoneOrderSave = data => post(`/v1/project/config/milestoneOrderSave`, data)

// 项目管理-项目管理-我的项目 项目详细信息
export const projectDetail = data => get(`/v1/project/detail`, data)

// 项目管理-项目管理-项目任务列表
export const taskList = data => get(`/v1/project/task/list`, data)

// 项目管理-项目管理-项目实际任务列表
export const treeList = data => get(`/v1/project/task/treeList`, data)

// 项目管理-里程碑-项目任务保存
export const taskSave = data => post(`/v1/project/task/save`, data)

// 项目管理-项目配置-中心设置 我的负责中心列表
export const myHospital = data => get(`/v1/project/config/myHospital`, data)

// 项目管理-项目配置-中心设置 我的负责中心列表
export const myProjectTask = data => get(`/v1/workTime/myProjectTask`, data)

// 项目任务工时列表
export const workHourslist = data => get(`/v1/project/workHours/list`, data)

// 项目任务更新
export const workHourstaskSave = data => post(`/v1/project/workHours/taskSave`, data)

// 项目任务工时列表保存
export const workHourssave = data => post(`/v1/project/workHours/save`, data)

// 项目角色权限保存
export const permissionSave = data => post(`/v1/project/role/permission/save`, data)

// 获取项目角色权限
export const permissionList = data => get(`/v1/project/role/permission/list`, data)

// 获取项目角色权限
export const myPermission = data => get(`/v1/project/role/my/permission`, data)

// 项目里程碑版本列表
export const milestoneList = data => get(`/v1/project/config/milestoneList`, data)

// 项目里程碑版本列表
export const addMilestoneVersion = data => post(`/v1/project/config/milestoneVersion`, data)

// 项目里程碑调整计划批量保存
export const milestoneBatchSave = data => post(`/v1/project/config/milestoneBatchSave`, data)

// 项目里程碑实际保存
export const milestoneCompleteSave = data => post(`/v1/project/config/milestoneCompleteSave`, data)

// 项目里程碑计划调整列表
export const milestoneReviseList = data => get(`/v1/project/config/milestoneReviseList`, data)

// 项目里程碑计划调整保存
// export const milestoneReviseSave = data => post(`/v1/project/config/milestoneReviseSave`, data)

// 项目里程碑提交审批
export const milestoneApprovalSubmit = data => post(`/v1/project/config/milestoneApprovalSubmit`, data)

// 项目里程碑提交审批撤回
export const milestoneApprovalRevoke = data => post(`/v1/project/config/milestoneApprovalRevoke`, data)

// 审批管理：项目里程碑审批
export const milestoneApproval = data => post(`/v1/project/config/milestoneApproval`, data)

// 项目阶段列表
export const phase = data => get(`/v1/project/config/phase`, data)

// 项目阶段保存
export const phaseSave = data => post(`/v1/project/config/phaseSave`, data)

// 项目阶段保存
export const phaseSaveOrder = data => post(`/v1/project/config/phaseSaveOrder`, data)

// 项目整体信息导出
export const projectExport = (data, onDownloadProgress, cancelToken) => postProgress(`/v1/project/export`, data, onDownloadProgress, cancelToken, true)

// 项目里程碑计划导出
export const milestonePlanExport = (data, onDownloadProgress, cancelToken) => postProgress(`/v1/project/milestonePlanExport`, data, onDownloadProgress, cancelToken, true)

// 项目里程碑预算工时导出
export const milestonePlanWorkTimeExport = (data, onDownloadProgress, cancelToken) => postProgress(`/v1/project/milestonePlanWorkTimeExport`, data, onDownloadProgress, cancelToken, true)

// 项目阶段保存
export const downLoadWbs = data => postProgress(`/v1/project/workHours/wbsExport`, data)

// 项目任务预算工时列表
export const budgetList = data => get(`/v1/project/workHours/budgetList`, data)

// 项目预算工时版本列表
export const budgetVersionList = data => get(`/v1/project/workHours/budgetVersionList`, data)

// 项目预算工时版本新增
export const budgetVersionSave = data => post(`/v1/project/workHours/budgetVersionSave`, data)

// 项目预算工时版本锁定/解锁
export const budgetVersionLock = data => post(`/v1/project/workHours/budgetVersionLock`, data)

// 季度形象进度配置列表
export const quarterlist = data => get(`/v1/project/config/quarter/list`, data)

// 季度形象进度配置保存
export const quartersave = data => post(`/v1/project/config/quarter/save`, data)

// 季度形象进度删除
export const quarterdelete = data => post(`/v1/project/config/quarter/delete`, data)

// 季度形象进度删除
export const quarterscheduleDate = data => get(`/v1/project/config/quarter/scheduleDate`, data)

// 周报项目选择
export const monthReportProjectList = data => get(`/v1/project/monthReport/myProject`, data)

// 项目月报列表(PM)
export const pmList = data => get(`/v1/project/monthReport/pm/list`, data)

// 项目月报详情(PM)
export const pmDetail = data => get(`/v1/project/monthReport/pm/detail`, data)

// 项目月报保存(PM)
export const pmSave = data => post(`/v1/project/monthReport/pm/save`, data)

// 项目月报删除(PM)
export const pmDelete = data => post(`/v1/project/monthReport/pm/delete`, data)

// 项目月报备注保存(PM)
export const remarkSave = data => post(`/v1/project/monthReport/pm/remarkSave`, data)

// 项目月报备注关闭(PM)
export const remarkClose = data => post(`/v1/project/monthReport/pm/remarkClose`, data)

// 项目月报工时获取(PM)
export const monthReportWorkTime = data => get(`/v1/project/monthReport/workTime`, data)

// 附件下载
export const monthReportDownload = data => postProgress(`/v1/project/monthReport/download`, data)

// 按月周报选择
export const getMonthList = data => get(`/v1/project/monthReport/monthList`, data)

// 项目月报列表(PML)
export const pmlList = data => get(`/v1/project/monthReport/pml/list`, data)

// 按月周报选择（PML）
export const monthPMLList = data => get(`/v1/project/monthReport/monthPMLList`, data)

// 项目月报详情(PML)
export const pmlDetail = data => get(`/v1/project/monthReport/pml/detail`, data)

// 项目月报保存(PML)
export const pmlSave = data => post(`/v1/project/monthReport/pml/save`, data)

// 项目月报创建(PML)
export const pmlCreate = data => post(`/v1/project/monthReport/pml/create`, data)

// 项目月报删除(PML)
export const pmlDelete = data => post(`/v1/project/monthReport/pml/delete`, data)

// 项目月报人员工时(PML)
export const pmlEmployeeWorkTime = data => get(`/v1/project/monthReport/pml/employeeWorkTime`, data)

// 项目月报备注保存(PML)
export const pmlRemarkSave = data => post(`/v1/project/monthReport/pml/remarkSave`, data)

// 项目月报备注关闭(PML)
export const pmlRemarkClose = data => post(`/v1/project/monthReport/pml/remarkClose`, data)

// 月报审批
export const monthReportApproval = data => post(`/v1/project/monthReport/approval`, data)

// 月报提交审批
export const monthReportApprovalSubmit = data => post(`/v1/project/monthReport/approvalSubmit`, data)

// 项目月报审批记录
export const monthReportApprovalRecord = data => get(`/v1/project/monthReport/approvalRecord`, data)

// 项目中心获取计划入组月份
export const hospitalPlanMonth = data => get(`/v1/project/config/hospitalPlanMonth`, data)

// 项目中心计划入组保存
export const hospitalPlanPatientSave = data => post(`/v1/project/config/hospitalPlanPatientSave`, data)
// 项目月报工时
export const projectWorkTime = data => get(`/v1/project/monthReport/pml/projectWorkTime`, data)

// 项目月报人员工时
export const employeeWorkTime = data => post(`/v1/project/monthReport/pml/employeeWorkTime`, data)

// 部门预算工时
export const departmentBudgetWorkTime = data => post(`/v1/workTime/departmentBudgetWorkTime`, data)

// PMD报告-PML人员
export const getPmlInfo = data => get(`/v1/project/monthReport/pmlInfo`, data)

// 项目月报列表(PMD)
export const pmdList = data => get(`/v1/project/monthReport/pmdList`, data)

// 项目月报创建(PMD)
export const pmdCreate = data => post(`/v1/project/monthReport/pmdCreate`, data)

// 项目月报备注保存(PMD)
export const pmdRemarkSave = data => post(`/v1/project/monthReport/pmdRemarkSave`, data)

// 项目月报删除(PMD)
export const pmdDelete = data => post(`/v1/project/monthReport/pmdDelete`, data)

// PMD报告
export const pmdReport = data => get(`/v1/project/monthReport/pmdReport`, data)

// PMD报告保存(PMD)
export const pmdReportSave = data => post(`/v1/project/monthReport/pmdReportSave`, data)
// 项目管理-项目中心信息
export const monIssueshospital = data => get(`/v1/project/monitoringIssues/hospital`, data)

// 项目管理-监查问题保存
export const monIssuesSave = data => post(`/v1/project/monitoringIssues/save`, data)

// 项目管理-监查问题列表
export const monIssuesList = data => get(`/v1/project/monitoringIssues/list`, data)

// 项目管理-监查问题附件保存
export const attachmentSave = data => post(`/v1/project/monitoringIssues/attachmentSave`, data)

// 项目管理-监查问题附件列表
export const attachmentList = data => get(`/v1/project/monitoringIssues/attachmentList`, data)

// 项目管理-监查问题附件下载
export const attachmentDownload = (data, onDownloadProgress, cancelToken) => postProgress(`/v1/project/monitoringIssues/attachmentDownload`, data, onDownloadProgress, cancelToken, true)

// 项目管理-监查问题删除
export const monitoringIssuesDelete = data => post(`/v1/project/monitoringIssues/delete`, data)

// 项目管理-监查问题导出
export const monitoringIssuesExport = (data, onDownloadProgress, cancelToken) => postProgress(`/v1/project/monitoringIssues/export`, data, onDownloadProgress, cancelToken, true)

//项目管理-项目月报人员工时导出
export const employeeWorkTimeExport = (data, onDownloadProgress, cancelToken) => postProgress(`/v1/project/monthReport/pml/employeeWorkTimeExport`, data, onDownloadProgress, cancelToken, true)

// 项目工具-回款计划列表
export const paymentPlanList = data => get(`/v1/tools/paymentPlan/list`, data)

// 项目工具-回款计划删除
export const paymentPlanDelete = data => post(`/v1/tools/paymentPlan/delete`, data)

// 项目工具-回款计划删除
export const paymentPlanSave = data => post(`/v1/tools/paymentPlan/save`, data)

// 项目管理-监查问题导出
export const paymentPlanExport = (data, onDownloadProgress, cancelToken) => postProgress(`/v1/tools/paymentPlan/export`, data, onDownloadProgress, cancelToken, true)

// 部门列表
export const projectDepartment = data => get(`/v1/project/department`, data)

// 项目管理-监查问题列表（疫苗）
export const vaccineList = data => get(`/v1/project/monitoringIssues/vaccineList`, data)

// 项目管理-上传监查问题表保存
export const vaccineUpload = (data, onDownloadProgress, cancelToken) => postProgress(`/v1/project/monitoringIssues/vaccineUpload`, data, onDownloadProgress, cancelToken, true)

// 项目管理-监查问题删除（疫苗）
export const vaccineDelete = data => post(`/v1/project/monitoringIssues/vaccineDelete`, data)

// 项目管理-监查问题导出（疫苗）
export const vaccineExport = (data, onDownloadProgress, cancelToken) => postProgress(`/v1/project/monitoringIssues/vaccineExport`, data, onDownloadProgress, cancelToken, true)

// co
// CO月报列表(CRM)
export const crmList = data => get(`/v1/project/monthReport/crm/list`, data)

// CO月报详情(CRM)
export const crmDetail = data => get(`/v1/project/monthReport/crm/detail`, data)

// CO月报保存(CRM)
export const crmSave = data => post(`/v1/project/monthReport/crm/save`, data)

// CO月报创建(CRM)
export const crmCreate = data => post(`/v1/project/monthReport/crm/create`, data)

// CO月报删除(CRM)
export const crmDelete = data => post(`/v1/project/monthReport/crm/delete`, data)

// CO月报提交审批(CRM)
export const coApprovalSubmit = data => post(`/v1/project/monthReport/co/approvalSubmit`, data)

// CO月报审批记录(CRM)
export const coApprovalRecord = data => get(`/v1/project/monthReport/co/approvalRecord`, data)

// CO月报审批(CRM)
export const coApproval = data => post(`/v1/project/monthReport/co/approval`, data)

// CO月报备注保存(CRM)
export const crmRemarkSave = data => post(`/v1/project/monthReport/crm/remarkSave`, data)

// CO月报备注关闭(CRM)
export const crmRemarkClose = data => post(`/v1/project/monthReport/crm/remarkClose`, data)

// CO管理的人员
export const coMyEmployee = data => get(`/v1/project/monthReport/co/myEmployee`, data)

// CO管理的人员项目
export const coMyProject = data => get(`/v1/project/monthReport/co/myProject`, data)

// 附件下载
export const coDownload = data => postProgress(`/v1/project/monthReport/co/download`, data)

// PM月报完成情况
export const pmReportComplete = data => get(`/v1/project/monthReport/pmReportComplete`, data)

// PM月报完成情况导出
export const pmReportCompleteExport = (data, onDownloadProgress, cancelToken) => postProgress(`/v1/project/monthReport/pmReportCompleteExport`, data, onDownloadProgress, cancelToken, true)

// 按月报选择（CRM）
export const getMonthCRMList = data => get(`/v1/project/monthReport/crm/monthCRMList`, data)

// 项目账期
export const accountPeriod = data => get(`/v1/project/milestone/accountPeriod`, data)

// 项目账期保存
export const accountPeriodSave = data => post(`/v1/project/milestone/accountPeriodSave`, data)

// 访视列表
export const siteVisit = data => get(`/v1/project/siteVisitList`, data)
