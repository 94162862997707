export default {
  path: '/cioms',
  component: () => import(/* webpackChunkName: 'cioms' */'@/views/cioms/layout/index'),
  meta: { requiresAuth: true },
  children: [
    {
      path: '/cioms',
      name: 'list',
      component: () => import(/* webpackChunkName: 'cioms' */'@/views/cioms/list')
    },
  ]
}