<template>
	<el-dialog v-loading="dialoading" custom-class="qx-dialogs" title="权限管理" width="500px" :append-to-body="true" :visible.sync="visible">
    <p style="margin-bottom: 10px;">授权文件夹</p>
    <p style="font-weight: 700;margin-bottom: 12px;display: flex;align-items: center;flex-wrap: wrap;">
      <img :src="root" style="width: 15px; height: 15px;" />
      <i class="el-icon-arrow-right" style="margin: 0 6px;color: #C0C4CC;"></i>
      <span v-for="(item, index) in fileInfo.file_path.split('/')"  :key="index" style="display: flex;align-items: center;word-break: keep-all;line-height: 26px;">
        <template v-if="item">
          <img :src="dir" style="width: 20px; height: 20px;margin-right: 4px;" />
          <span>{{ item }}</span>
          <i class="el-icon-arrow-right" style="margin: 0 6px;color: #C0C4CC;"></i>
        </template>
      </span>
      <span v-if="fileInfo.file_name" style="display: flex;align-items: center;word-break: keep-all;">
        <img :src="dir" style="width: 20px; height: 20px;margin-right: 4px;" />
        <span>{{ fileInfo.file_name }}</span>
      </span>
    </p>
    <!-- <p style="font-weight: 700;margin-bottom: 12px;">{{ (fileInfo.file_path === '/' ? '/' : (fileInfo.file_path)) + (fileInfo.file_name ? ('/' + fileInfo.file_name) : '') }}</p> -->
    <div class="top-title-qx">
      <span>所有协作者</span>
      <span style="color: #409EFF;cursor: pointer;" @click="addDepartment">
        <i class="el-icon-plus"></i>
        添加协作者
      </span>
    </div>
    <div class="user-block">
      <div class="top-check-all">
        <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">全选</el-checkbox>
        <el-dropdown v-if="checkAll" trigger="click" placement="bottom-start" @command="handleCommandAll($event)">
          <el-button type="primary" size="mini">
            修改权限<i class="el-icon-arrow-down el-icon--right"></i>
          </el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item v-for="(items, index) in power" :key="index" :command="items">
              <div style="display: flex;justify-content: space-between;align-items: center;min-width: 400px;">
                <div style="display: flex;align-items: baseline;">
                  <i v-if="items.value === 1" class="el-icon-setting"></i>
                  <i v-if="items.value === 2" class="el-icon-edit"></i>
                  <i v-if="items.value === 3" class="el-icon-download"></i>
                  <i v-if="items.value === 4" class="el-icon-view"></i>
                  <i style="color: red;" v-if="items.value === 5" class="el-icon-delete"></i>
                  <div style="margin-left: 3px;">
                    <p v-if="items.value === 5" style="color: red;font-size: 14px;line-height: 20px;">{{ items.label }}</p>
                    <p v-else style="color: #2b2e30;font-size: 14px;line-height: 20px;">{{ items.label }}</p>
                    <p class="last-p" style="color: #808283;font-size: 12px;line-height: 20px;">{{ items.detail }}</p>
                  </div>
                </div>
                <!-- <i class="el-icon-check" v-if="item.power === items.value"></i> -->
              </div>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
      <div class="bottom-user-list">
        <el-checkbox-group v-model="selectedColumn" @change="handleCheckedChange">
          <div v-for="item in columnOptions" :key="item.value" class="line-block">
            <div>
              <el-checkbox :label="item.value" :disabled="item.create ? true : false">
                <el-image
                style="width: 30px; height: 30px;margin-right: 10px;border-radius: 4px;background: #f8f6f7;"
                :src="item.avatar || avatar"
                fit="contain"
                ></el-image>
                {{ item.label }}
              </el-checkbox>
            </div>
            <span style="font-size: 14px;color: #C0C4CC;" v-if="item.power === 1 && item.create">{{ item.power_name }}</span>
            <span style="font-size: 14px;" v-else-if="checkAll">{{ item.power_name }}</span>
            <el-dropdown v-else trigger="click" placement="bottom-start" @command="handleCommand($event, item)">
              <span class="el-dropdown-link">
                {{ item.power_name }}<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item v-for="(items, index) in power" :key="index" :command="items">
                  <div style="display: flex;justify-content: space-between;align-items: center;min-width: 400px;">
                    <div style="display: flex;align-items: baseline;">
                      <i v-if="items.value === 1" class="el-icon-setting"></i>
                      <i v-if="items.value === 2" class="el-icon-edit"></i>
                      <i v-if="items.value === 3" class="el-icon-download"></i>
                      <i v-if="items.value === 4" class="el-icon-view"></i>
                      <i style="color: red;" v-if="items.value === 5" class="el-icon-delete"></i>
                      <div style="margin-left: 3px;">
                        <p v-if="items.value === 5" style="color: red;font-size: 14px;line-height: 20px;">{{ items.label }}</p>
                        <p v-else style="color: #2b2e30;font-size: 14px;line-height: 20px;">{{ items.label }}</p>
                        <p class="last-p" style="color: #808283;font-size: 12px;line-height: 20px;">{{ items.detail }}</p>
                      </div>
                    </div>
                    <i class="el-icon-check" v-if="item.power === items.value"></i>
                  </div>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
          
        </el-checkbox-group>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="visible = false">取 消</el-button>
      <el-button type="primary" @click="dialogOkQx()">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { powerList, getUserPower, userPowerSave } from '_s/fileStorage3.js'
import avatar from '@/assets/icon/default.png'
import root from '@/assets/icon/root.png'
import dir from '@/assets/icon/dir.png'
// import { deleteFileByFalse, deleteFolderByFalse, powerList } from '_s/fileStorage3.js'
export default {
  name: 'QxFileDialog',
  data () {
    return {
      dialoading: false,
      avatar: avatar,
      root: root,
      dir: dir,
      visible: false, //  对话框是否可见
      isIndeterminate: false,
      checkAll: false,
      allUsers: [],
      selectedColumn: [],
      columnOptions: [
        // {
        //   label: '杨婷婷',
        //   value: 1
        // },
        // {
        //   label: '关宁',
        //   value: 2
        // },
      ],
      power: [],
      delArr: [],
      oldData: ''
    }
  },
  created () {
    this.gePowerList()
    this.getUserPower()
  },
  methods: {
    handleCommandAll(a) {
      this.columnOptions.forEach((item) => {
        if (!item.create) {
          item.power = a.value
          item.power_name = a.label
        }
      })
    },
    handleCommand(a, item) {
      console.log(item, this.columnOptions)
      if (a.value === 5) {
        item.power = 5
        this.delArr.push(item)
        this.columnOptions = this.columnOptions.filter(items => items.uid != item.uid)
      } else {
        item.power = a.value
        item.power_name = a.label
      }
      
    },
    async gePowerList () {
      const { data } = await powerList()
      data.push({
        label: '移除',
        value: 5
      })
      this.power = data
    },
    async getUserPower () {
      const { data } = await getUserPower({ folder_id: this.fileInfo.id })
      this.oldData = JSON.stringify(data)
      data.forEach((item) => {
        item.label = item.user_name
        item.value = item.uid
        if (!item.create) {
          this.allUsers.push(item.uid)
        }
      })
      this.columnOptions = data
    },
    addDepartment() {
      let fileInfo = this.fileInfo
      let columnOptions = this.columnOptions
      this.$openDialog3.departmentMember({ fileInfo },{ columnOptions }).then((res) => {
        
        // let columnOptions = []
        // let selectedColumn = []
        res.forEach((item) => {
          this.columnOptions.push({
            label: item.name,
            value: item.id,
            power: 2,
            power_name: '可编辑',
            name: item.name,
            user_name: item.user_name,
            avatar: item.avatar
          })
          this.allUsers.push(item.id)
        })
        this.columnOptions = columnOptions
        console.log(this.columnOptions,this.allUsers,'this.columnOptionsthis.columnOptionsthis.columnOptions')
        // this.selectedColumn = selectedColumn
        // this.allUsers = this.selectedColumn
      })
    },
    async dialogOkQx() {
      console.log(this.columnOptions,'save')
      let arr = []
      this.columnOptions.forEach((item) => {
        // if (!item.create) {
        if (!item.op_user_id) {
          arr.push({
            uid: item.value,
            power: item.power,
            name: item.name || item.user_name,
            avatar: item.avatar,
            op_user_id: item.op_user_id,
            op_user_name: item.op_user_name,
            created_at: item.created_at
          })
        } else {
          JSON.parse(this.oldData).forEach((items) => {
            if (items.uid === item.uid) {
              if (items.power !== item.power) {
                arr.push({
                  uid: item.value,
                  power: item.power,
                  name: item.name || item.user_name,
                  avatar: item.avatar,
                  op_user_id: item.op_user_id,
                  op_user_name: item.op_user_name,
                  created_at: item.created_at
                })
              }
            }
          })
        }
        // }
      })
      arr = arr.concat(this.delArr)
      this.dialoading = true
      const data = await userPowerSave({
        folder_id: this.fileInfo.id,
        power_data: arr
      })
      if (data.code === 0) {
        this.dialoading = false
        this.visible = false
        this.callback()
      } else {
        this.dialoading = false
      }
    },
    handleCheckAllChange(val) {
      this.selectedColumn = val ? this.allUsers : [];
      this.isIndeterminate = false;
    },
    handleCheckedChange(value) {
      let checkedCount = value.length;
      console.log(checkedCount, this.allUsers.length)
      this.checkAll = checkedCount === this.allUsers.length;
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.allUsers.length;
    }
  }
}
</script>
<style lang="scss" scoped>
.qx-dialogs {
  .top-title-qx {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .user-block {
    width: 100%;
    height: 300px;
    border-radius: 6px;
    border: 1px solid #eaeced;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    .top-check-all {
      display: flex;
      justify-content: space-between;
      padding: 10px;
      border-bottom: 1px solid #eaeced;
    }
    .bottom-user-list {
      flex: 1;
      overflow-y: scroll;
      padding: 10px 16px;
      .line-block {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 14px;
      }
    }
  }
}
::v-deep .el-dialog__body {
  padding: 10px 20px;
}
::v-deep .el-dropdown-menu__item {
  padding: 6px 20px!important;
}
::v-deep .el-popper {
  padding: 0!important;
}
::v-deep .el-checkbox {
  display: flex;
  align-items: center;
}
::v-deep .el-checkbox__label {
  display: flex;
  align-items: center;
}

</style>