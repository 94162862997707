export default {
  path: '/companyFile',
  component: () => import(/* webpackChunkName: 'layout' */'@/views/companyFile/layout/index'),
  meta: { requiresAuth: true },
  children: [
    {
      path: '',
      name: 'file',
      component: () => import(/* webpackChunkName: 'file' */'@/views/companyFile/index')
    },
    {
      path: '/companyFile/recycle',
      name: 'recycle',
      component: () => import(/* webpackChunkName: 'recycle' */'@/views/companyFile/recycle/index')
    },
    {
      path: '/companyFile/power',
      name: 'power',
      component: () => import(/* webpackChunkName: 'power' */'@/views/companyFile/power/index')
    },
    {
      path: '/companyFile/log',
      name: 'log',
      component: () => import(/* webpackChunkName: 'log' */'@/views/companyFile/slog/index')
    },
    {
      path: '/companyFile/folder',
      name: 'folder',
      component: () => import(/* webpackChunkName: 'folder' */'@/views/companyFile/folder/index')
    },
    {
      path: '/companyFile/group',
      name: 'group',
      component: () => import(/* webpackChunkName: 'group' */'@/views/companyFile/group/index')
    },
    {
      path: '/companyFile/downloadTask',
      name: 'downloadTask',
      component: () => import(/* webpackChunkName: 'group' */'@/views/companyFile/downloadTask/index')
    }
  ]
}