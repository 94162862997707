<template>
	<!-- 预览文件弹窗 -->
	<el-drawer
		:title="fileInfo.file_name"
		:visible.sync="visible"
		size="100%"
		direction="rtl"
		:destroy-on-close="true"
		:before-close="handleClose"
		@opened="opened"
	>
		<div style="height: 100%" id="output" ref="output"></div>
	</el-drawer>
</template>

<script>
// import { previewType } from '_s/fileStorage3.js'
import store from '@/store'
export default {
  name: 'DownLoadFileDialog',
  data () {
    return {
      visible: false, // 抽屉是否可见
      document_type: ''
    }
  },
  methods: {
    async initPreview () {                                                    // 初始化预览，判断需要预览的文件类型，分别处理
      // const { data } = await previewType({ id: this.fileInfo.id })            // 获得需要预览的文件数据
      // this.document_type = data.document_type
      this.document_type = this.fileInfo.extend          
    },
    handleClose () { //手动关闭抽屉
      this.visible = false
    },
    opened () {
      let officeType = '';
      if (this.document_type == 'docx') {
        officeType = 'w'
      }
      if (this.document_type == 'xlsx') {
        officeType = 's'
      }
      if (this.document_type == 'pptx') {
        officeType = 'p'
      }
      if (this.document_type == 'pdf') {
        officeType = 'f'
      }
      const { token } = store.state.user
      var _this = this;
      window.WebOfficeSDK.init({
        officeType,
        appId: 'SX20231023DSLTMY',
        fileId: _this.fileInfo.id,
        mount: '#output',
        token: token.token_type + ' ' + token.access_token
      })
    }
  },
  created () {
    this.initPreview()
  }
}
</script>

<style lang="scss" scoped>
</style>