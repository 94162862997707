<template>
	<!-- 右键列表 -->
	<transition name="el-fade-in-linear">
		<!-- 在某个文件上右键 -->
		<ul
			class="right-menu-list"
			id="rightMenuList"
			v-show="visible"
			v-if="selectedFile !== undefined"
			:style="`top: ${rightMenu.top};right: ${rightMenu.right};bottom: ${rightMenu.bottom};left: ${rightMenu.left};`"
		>
			<li
				class="right-menu-item"
				@click="
					$file2.handleFileNameClick(selectedFile, 0)
				"
				v-if="seeBtnShow && !(selectedFile.is_dir && selectedFile.lock)"
			>
				<i class="el-icon-view"></i> 查看
			</li>
			<li
				class="right-menu-item"
				@click="$file2.handleWPSView(selectedFile, 0)"
				v-if="wpsBtnShow"
			>
				<i class="el-icon-view"></i> WPS查看
			</li>
			<li
				class="right-menu-item"
				@click="handleDeleteFileBtnClick(selectedFile)"
				v-if="deleteBtnShow && permission.power === 1 && !(selectedFile.is_dir && selectedFile.lock)"
			>
				<i class="el-icon-delete"></i> 删除
			</li>
			<li
				class="right-menu-item"
				@click="handleRestoreFileBtnClick(selectedFile)"
				v-if="restoreBtnShow && permission.power !== 9"
			>
				<i class="el-icon-refresh-left"></i> 还原
			</li>
			<li
				class="right-menu-item"
				@click="handleRenameFileBtnClick(selectedFile)"
				v-if="(permission.power === 1 || permission.power === 2) && !(selectedFile.is_dir && selectedFile.lock)"
			>
				<i class="el-icon-edit-outline"></i> 重命名
			</li>
			<li
				class="right-menu-item"
				@click="handleDownloadFile(selectedFile)"
				v-if="downloadBtnShow && permission.power !== 9 && permission.power !== 4"
			>
				<i class="el-icon-download"></i> 下载
			</li>
			<li
				class="right-menu-item"
				@click="handleShowDetailInfo(selectedFile)"
				v-if="detailInfoBtnShow"
			>
				<i class="el-icon-document"></i> 文件详情
			</li>
      <li class="right-menu-item" @click="handleRemove(selectedFile)" v-if="(permission.power === 1 || permission.power === 2) && !(selectedFile.is_dir && selectedFile.lock)">
				<i class="el-icon-folder-remove"></i> 移动
			</li>
      <li class="right-menu-item" @click="handleDowloadAll(selectedFile)" v-if="downloadallBtnShow && permission.power === 1 && !(selectedFile.is_dir && selectedFile.lock)">
				<i class="el-icon-sold-out"></i> 打包下载
			</li>
			<li class="right-menu-item" @click="handleCloseRightMenu()">
				<i class="el-icon-circle-close"></i> 取消
			</li>
      <li class="right-menu-item" @click="handleLock(selectedFile)" v-if="isDir && !selectedFile.lock">
				<i class="el-icon-lock"></i> 锁定
			</li>
      <li class="right-menu-item" @click="handleUnlock(selectedFile)" v-if="isDir && selectedFile.lock">
				<i class="el-icon-unlock"></i> 解锁
			</li>
      <el-divider v-if="isDir && permission.power === 1 && !(selectedFile.is_dir && selectedFile.lock)" />
      <li class="right-menu-item" v-if="isDir && permission.power === 1 && !(selectedFile.is_dir && selectedFile.lock)" @click="qxClick(selectedFile)">
        <i class="el-icon-lock"></i> 权限管理
      </li>
		</ul>
		<!-- 在空白处右键，右键列表展示刷新，新建文件夹等操作按钮 -->
		<ul
			class="right-menu-list add"
			id="rightMenuList"
			v-show="visible"
			v-else
			:style="`top: ${rightMenu.top};right: ${rightMenu.right};bottom: ${rightMenu.bottom};left: ${rightMenu.left};`"
		>
			<li class="right-menu-item" @click="callback('confirm')">
				<i class="el-icon-refresh"></i> 刷新
			</li>
			<template v-if="fileType === 0">
				<!-- <el-divider />
				<li class="right-menu-item" @click="handleClickAddFolderBtn">
					<i class="el-icon-folder-add"></i> 新建文件夹
				</li> -->
				<!-- <el-divider v-if="uploadFileBtnShow" />
				<li
					class="right-menu-item"
					@click="handleUploadFileBtnClick(1)"
					v-if="uploadFileBtnShow"
				>
					<i class="el-icon-upload2"></i> 上传文件
				</li> -->
				<el-divider />
				<li class="right-menu-item" @click="handleCloseRightMenu()">
					<i class="el-icon-circle-close"></i> 取消
				</li>
			</template>
		</ul>
	</transition>
</template>

<script>
import router from '@/router'
import { lockFolder } from '_s/fileStorage2';
export default {
  name: 'ContextMenu',
  data () {
    return {
      visible: false, //  右键菜单是否显示
      sortedFileList: [], //  排序后的表格数据
      // 右键菜单
      rightMenu: {
        top: 0,
        left: 0,
        bottom: 'auto',
        right: 'auto'
      },
      // 右键解压缩菜单
      unzipMenu: {
        top: 0,
        bottom: 'auto',
        left: '126px',
        right: 'auto'
      },
      dirImg: require('_a/images/file/dir.png'),
      wordImg: require('_a/images/file/file_word.svg'),
      excelImg: require('_a/images/file/file_excel.svg'),
      pptImg: require('_a/images/file/file_ppt.svg')
    }
  },
  computed: {
    // 路由名称
    routeName () {
      return router.currentRoute.name
    },
    // 左侧菜单选中的文件类型
    fileType () {
      return router.currentRoute.query.fileType
        ? Number(router.currentRoute.query.fileType)
        : 0
    },
    // 当前路径
    file_path () {
      return router.currentRoute.query.file_path
    },
    folder_id () {
      return router.currentRoute.query.folderId
    },
    uploadFileBtnShow () {
      return this.folder_id != 0
    },
    // 查看按钮是否显示
    seeBtnShow () {
      return this.fileType !== 6 && this.fileType === 0
    },
    wpsBtnShow () {
      return this.fileType !== 6 && this.fileType === 0 && this.selectedFile.is_dir === 0
    },
    isDir() {
      return this.selectedFile.is_dir === 1
    },
    // 删除按钮是否显示
    deleteBtnShow () {
      // return this.selectedFile.is_dir === 0 && this.fileType === 0
      return this.fileType === 0
    },
    // 还原按钮是否显示
    restoreBtnShow () {
      return this.fileType === 6 && !['Share'].includes(this.routeName)
    },
    // 重命名按钮是否显示
    renameBtnShow () {
      return this.selectedFile.is_dir === 1
    },
    // 打包下载按钮显示
    downloadallBtnShow() {
      return this.selectedFile.is_dir === 1 && this.fileType === 0
    },
    // 下载按钮是否显示
    downloadBtnShow () {
      return this.selectedFile.is_dir === 0 && this.fileType === 0
    },
    // 文件详情按钮是否显示
    detailInfoBtnShow () {
      return true
    },
    // 上传文件组件参数
    uploadFileParams () {
      return {
        file_path: this.file_path,
        is_dir: 0,
        folder_id: router.currentRoute.query.folderId
      }
    }
  },
  watch: {
    /**
     * 监听右键列表状态
     * @description 右键列表打开时，body 添加点击事件的监听
     */
    visible (newValue) {
      if (newValue === true) {
        this.handleOpenContextMenu()
        setTimeout(() => {
          document.body.addEventListener('click', this.closeRightMenu)
        }, 300)
      } else {
        document.body.removeEventListener('click', this.closeRightMenu)
      }
    }
  },
  methods: {
    handleLock(selectedFile) {
      this.$confirm(`此操作将锁定 ${selectedFile.file_name} ， 是否继续?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        beforeClose: async(action, instance, done) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true;
            const data = await lockFolder({ folder_id: selectedFile.id, lock: 1 })
            instance.confirmButtonLoading = false;
            if (data.code == 0) {
              done()
              this.callback('refresh')
            }
          } else {
            done()
          }
        }
      })
    },
    handleUnlock(selectedFile) {
      this.$confirm(`此操作将解锁 ${selectedFile.file_name} ， 是否继续?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        beforeClose: async(action, instance, done) => {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true;
            const data = await lockFolder({ folder_id: selectedFile.id, lock: 0 })
            instance.confirmButtonLoading = false;
            if (data.code == 0) {
              done()
              this.callback('refresh')
            }
          } else {
            done()
          }
        }
      })
    },
    qxClick(fileInfo) {
      this.$openDialog2.qxSet({ fileInfo }).then(() => {
        this.callback('refresh')
      })
    },
    handleRemove(fileInfo) {
      this.$openDialog2.remove({ fileInfo }).then(() => {
        this.callback('refresh')
      })
    },
    handleDowloadAll(fileInfo) {
      this.$openDialog2.createDownloadTask({ fileInfo }).then(res => {
        console.log(res)
        // if (res.done) {
        //   this.initData()
        // }
      })
    },
    /**
     * 打开右键菜单
     */
    handleOpenContextMenu () {
      // 纵坐标设置
      if (
        document.body.clientHeight - this.domEvent.clientY <
        document.querySelectorAll('#rightMenuList > .right-menu-item').length *
        36 +
        10
      ) {
        // 如果到底部的距离小于元素总高度
        this.rightMenu.top = 'auto'
        this.rightMenu.bottom = `${document.body.clientHeight - this.domEvent.clientY
          }px`
        this.unzipMenu.top = 'auto'
        this.unzipMenu.bottom = '0px'
      } else {
        this.rightMenu.top = `${this.domEvent.clientY}px`
        this.rightMenu.bottom = 'auto'
        this.unzipMenu.top = '0px'
        this.unzipMenu.bottom = 'auto'
      }
      // 横坐标设置
      if (document.body.clientWidth - this.domEvent.clientX < 138) {
        // 如果到右边的距离小于元素总宽度
        this.rightMenu.left = 'auto'
        this.rightMenu.right = `${document.body.clientWidth - this.domEvent.clientX
          }px`
        this.unzipMenu.left = '-200px'
        this.unzipMenu.right = 'auto'
      } else {
        this.rightMenu.left = `${this.domEvent.clientX + 8}px`
        this.rightMenu.right = 'auto'
        this.unzipMenu.left = '126px'
        this.unzipMenu.right = 'auto'
      }
      this.visible = true
    },
    /**
     * 关闭右键列表
     */
    closeRightMenu (event) {
      if (
        !event.target.className.includes('operate-more-') &&
        !event.target.className.includes('unzip-menu-item')
      ) {
        this.visible = false
        if (this.selectedFile !== undefined) {
          // 不是在空白处右键时
          // this.callback('cancel')
        }
      }
    },
    /**
     * 删除按钮点击事件
     * @description 区分 删除到回收站中 | 在回收站中彻底删除，打开确认对话框
     * @param {object} fileInfo 文件信息
     */
    handleDeleteFileBtnClick (fileInfo) {
      // if (this.permission.download !== 1) {
      //   this.$message.warning({ message: '抱歉，你没有此文件夹的删除权限' })
      //   return false
      // }
      this.visible = false
      this.$openDialog2
        .deleteFile({
          isBatchOperation: false,
          fileInfo,
          deleteMode: this.fileType === 6 ? 2 : 1 //  删除类型：1-删除到回收站 2-彻底删除
        })
        .then((res) => {
          this.callback(res)
        })
    },
    /**
     * 还原按钮点击事件
     * @description 调用接口，在回收站中还原文件
     * @param {object} fileInfo 文件信息
     */
    handleRestoreFileBtnClick (fileInfo) {
      this.visible = false
      this.$openDialog2.restoreFile({ fileInfo }).then((res) => {
        this.callback(res)
      })
    },
    /**
     * 文件重命名按钮点击事件
     * @description 打开确认对话框让用户输入新的文件名
     * @param {object} fileInfo 文件信息
     */
    handleRenameFileBtnClick (fileInfo) {
      this.visible = false

      if (fileInfo.is_dir === 0) { // 重命名文件
        // if (this.permission.upload !== 1) {
        //   this.$message.warning({ message: '抱歉，你没有此文件的重命名权限' })
        //   return false
        // }
        this.$openDialog2
          .renameFile({
            oldFileName: fileInfo.file_name,
            userFileId: fileInfo.id
          })
          .then((res) => {
            this.callback(res)
          })
      } else { // 重命名文件夹
        let name, order, folderId, parentId
        folderId = fileInfo.id
        parentId = fileInfo.parentId
        name = fileInfo.file_name
        order = 0
        const fs = this.file_path && this.file_path.split('/') || []
        let parentName = fs[fs.length - 1]
        this.$openDialog2.addOrEditFolder({ folderId, parentId, name, type: 0, parentName, order, showCascader: true }).then(() => {
          this.callback('confirm')
        })
      }
    },
    /**
       * 下载按钮点击事件
       * @description 打开显示下载进度的对话框
       * @param {object} fileInfo 文件信息
       */
    handleDownloadFile (fileInfo) {
      // if (this.permission.download !== 1) {
      //   this.$message.warning({ message: '抱歉，你没有此文件夹的下载权限' })
      //   return false
      // }
      this.visible = false
      this.$openDialog2.downLoadFile({ fileInfo })
    },
    /**
     * 文件详情按钮点击事件
     * @description 打开对话框展示文件完整信息
     * @param {object} fileInfo 文件信息
     */
    handleShowDetailInfo (fileInfo) {
      this.visible = false
      this.$openDialog2.showFileDetail({ fileInfo })
    },
    /**
     * 取消按钮点击事件
     */
    handleCloseRightMenu () {
      this.visible = false
    },
    /**
     * 新建文件夹按钮点击事件
     * @description 调用新建文件夹服务，并在弹窗确认回调事件中刷新文件列表
     */
    handleClickAddFolderBtn () {
    },
    /**
     * 上传文件按钮点击事件
     * @description 通过Bus通信，开启全局上传文件流程
     * @param {boolean} uploadWay 上传方式 0-文件上传 1-文件夹上传 2-粘贴图片或拖拽上传
     */
    handleUploadFileBtnClick (uploadWay) {
      // if (this.permission.upload !== 1) {
      //   this.$message.warning({ message: '抱歉，你没有此文件夹的上传权限' })
      //   return false
      // }
      this.$openBox2.uploadFile({
        params: this.uploadFileParams,
        uploadWay,
        serviceEl: this.serviceEl,
        callType: true //  callType 调用此服务的方式：1 - 顶部栏，2 - 右键菜单
      })
    }
  },
  created () {
  }
}
</script>

<style lang="scss" scoped>
.right-menu-list {
	position: fixed;
	display: flex;
	flex-direction: column;
	background: #fff;
	border: 1px solid #ebeef5;
	border-radius: 4px;
	box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
	z-index: 2;
	color: #606266;
	list-style: none;

	.el-divider {
		margin: 0 !important;
	}

	.right-menu-item {
		padding: 0 8px;
		height: 36px;
		line-height: 36px;
    border-radius: 4px;
		cursor: pointer;

		&:hover {
			background: #ecf5ff;
			color: #409eff;
		}

		i {
			// margin-right: 8px;
		}
	}

	&.add {
		.right-menu-item {
			display: flex;
			align-items: center;

			img {
				margin-right: 4px;
				height: 20px;
			}

			i {
				margin-right: 4px;
				font-size: 18px;
			}
		}
	}
}

.right-menu-list {
  width: 160px;
	background: #fff;
	border: 1px solid #ebeef5;
	border-radius: 6px;
	box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
	z-index: 2;
	padding: 2px 4px;
	color: #606266;
	font-size: 14px;
}
</style>
