<template>
	<el-dialog custom-class="qx-dialogs" title="请选择移动的目标文件夹" width="800px" :append-to-body="true" :visible.sync="visible">
		<div class="content">
			<div class="left">
        <div style="height: calc(100% - 50px);">
          <!-- <p style="margin-bottom: 12px;font-size: 18px;color: #303133;">移动到</p> -->
          <el-cascader
            :props="props"
            v-model="form.folder_id"
            style="width: 100%"
            :show-all-levels="false"
            ref="elCascader"
            class="elCascader"
          >
            <template slot-scope="{ data }">
              <span style="display: flex;align-items: center;">
                <img
                  :src="$file3.setFileImg({is_dir: 1})"
                  style="width: 20px; height: 20px;margin-right: 4px;"
                />
                <span style="max-width: 220px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;">{{ data.label }}</span>
              </span>
            </template>
          </el-cascader>
        </div>
			</div>
		</div>
		<span slot="footer" class="dialog-footer">
			<el-button @click="visible = false" size="medium"
				>取消</el-button
			>
			<el-button
				type="primary"
				size="medium"
				v-loading="btnLoad"
				@click="saveBtn"
				>确定</el-button
			>
		</span>
	</el-dialog>
</template>

<script>
// import TablePage from '_c/tablePage';
import { getFileList, fileMoveFolderFile, fileMove  } from '_s/fileStorage3'
export default {
  components: {
    // TablePage
  },
  data () {
    return {
      visible: false,
      form: {
        folder_id: 0
      },
      props: {
        emitPath: false,
        checkStrictly: true,
        lazy: true,
        lazyLoad (node, resolve) {
          console.log(node,'node')
          getFileList({ folder_id: node.value }).then(res => {
            console.log(res,'res')
            let arr = []
            res.data.list.forEach((item) => {
              if (item.is_dir) {
                arr.push(item)
              }
            })
            const node = arr.map(item => ({ value: item.id, label: item.file_name, leaf: false }))
            resolve(node)
          })
        }
      },
      btnLoad: false
    }
  },
  watch: {
  },
  methods: {
    loadNode(node, resolve) {
      console.log(node, resolve)
    },
    u_filterNode (value, data) {
      if (!value) return true;
      return data.name.indexOf(value) !== -1;
    },
    async saveBtn () {
      if (Array.isArray(this.fileInfo)) {
        let arr = []
        this.fileInfo.forEach((item) => {
          arr.push({
            id: item.id,
            is_dir: item.is_dir
          })
        })
        const data = await fileMoveFolderFile({
          parent_id: this.form.folder_id,
          ids: arr
        })
        if (data.code === 0) {
          this.visible = false
          this.callback('refresh')
        }
      } else {
        const data = await fileMove({
          parent_id: this.form.folder_id,
          id: this.fileInfo.id
        })
        if (data.code === 0) {
          this.visible = false
          this.callback('refresh')
        }
        // if (this.fileInfo.is_dir) {
        //   const data = await fileMoveFolder({
        //     parent_id: this.form.folder_id,
        //     folder_id: this.fileInfo.id
        //   })
        //   if (data.code === 0) {
        //     this.visible = false
        //     this.callback('refresh')
        //   }
        // } else {
        //   const data = await fileMove({
        //     folder_id: this.form.folder_id,
        //     id: this.fileInfo.id
        //   })
        //   if (data.code === 0) {
        //     this.visible = false
        //     this.callback('refresh')
        //   }
        // }
      }
      
      
      // this.btnLoad = true
      // const data = await userPowerSave({
      //   folder_id: this.fileInfo.id,
      //   power_data: []
      // })
      // if (data.code === 0) {
      //   this.visible = false
      // }
      // this.btnLoad = false
    },
    setDisabled(item) {
      if (item.children) {
        item.children.forEach((items) => {
          if (!items.is_dir) {
            items.disabled = true
          } else {
            items.disabled = false
          }
          this.setDisabled(items)
        })
      }
    },
    async u_showFileList () {
      this.u_loading = true
      const { data } = await getFileList()
      data.list.forEach((item) => {
        if (!item.is_dir) {
          item.disabled = true
        } else {
          item.disabled = false
        }
        this.setDisabled(item)
      })
      this.u_data = data.list
      this.u_loading = false
    },
    u_handleCheckChange (node, nodeData) {
      this.nodeData = nodeData
      this.isChangeTree = true
    },
  },
  created () {
    console.log(this.fileInfo,'fileInfos')
    this.u_showFileList()

  }
}
</script>
<style lang="scss" scoped>
:deep(.el-dialog__body) {
	padding: 0;
}


:deep(.el-dialog__footer) {
	padding: 20px;
}
.content {
	display: flex;
	height: 300px;

	.left {
		word-break: break-all;
		padding: 20px;
		width: 100%;
		border-right: 1px solid #eaeced;
		// border-top: 1px solid #eaeced;
		border-bottom: 1px solid #eaeced;
		text-overflow: ellipsis;
		// overflow-y: auto;
	}
}
</style>