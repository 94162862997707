<template>
	<el-dialog custom-class="qx-dialogs" title="" width="800px" :append-to-body="true" :visible.sync="visible">
		<div class="content">
			<div class="left">
        <el-input placeholder="输入人名进行搜索" v-model="u_filterText" clearable></el-input>
        <div style="height: calc(100% - 50px);overflow: scroll;margin-top: 10px;">
          <el-tree
            :data="u_data"
            ref="u_tree"
            :props="u_defaultProps"
            show-checkbox
            v-loading="u_loading"
            @check="u_handleCheckChange"
            node-key="id"
            :default-expanded-keys="u_dExpandedKeys"
            :default-checked-keys="u_dCheckedKeys"
            :filter-node-method="u_filterNode"
          >
            <span class="custom-tree-node" slot-scope="{ data }">
              <span style="margin-right: 15px">{{ data.name }}</span>
              <el-tag
                style="margin-right: 15px"
                v-if="data.position"
                size="mini"
                >{{ data.position }}</el-tag
              >
              <el-tag v-if="data.leader" size="mini">主管</el-tag>
            </span>
          </el-tree>
        </div>
			</div>
			<div class="right">
				<p style="height: 30px;">已选人员：</p>
        <div style="flex:1;overflow: scroll;">
          <div style="width: 100%;height: 100%;overflow: scroll;">
            <span style="margin: 4px;display: inline-block;padding: 4px 10px;background: #f8f8f8;border-radius: 4px;" v-for="(item, index) in u_tableData" :key="index">
              {{ item.name }}
              <!-- {{ firstplayLoad }}
              {{ item.uid }} -->
              <i v-if="firstplayLoad.indexOf(item.uid) < 0" style="color: #c2c3c3;" class="el-icon-error" @click="delUserCheck(item)"></i>
            </span>
          </div>
        </div>
			</div>
		</div>
		<span slot="footer" class="dialog-footer">
			<el-button @click="visible = false" size="medium"
				>取消</el-button
			>
			<el-button
				type="primary"
				size="medium"
				v-loading="btnLoad"
				@click="saveBtn"
				>确定</el-button
			>
		</span>
	</el-dialog>
</template>

<script>
// import TablePage from '_c/tablePage';
import { v2departmentMember } from '_s/fileStorage3';
export default {
  components: {
    // TablePage
  },
  data () {
    return {
      visible: false,
      u_data: [],
      u_defaultProps: {
        children: 'children',
        label: 'name'
      },
      u_loading: false,
      nodeData: {},
      firstplayLoad: [],
      u_tableData: [],
      u_filterText: '',
      u_dCheckedKeys: [],
      u_dExpandedKeys: ['p1'],
      isChangeTree: false,
      btnLoad: false
    }
  },
  watch: {
    u_filterText (val) {
      this.$refs.u_tree.filter(val);
    }
  },
  methods: {
    u_filterNode (value, data) {
      if (!value) return true;
      return data.name.indexOf(value) !== -1;
    },
    async saveBtn () {
      this.visible = false
      console.log(this.u_tableData,'this.u_tableData')
      let newData = []
      this.u_tableData.forEach((item) => {
        if (this.firstplayLoad.indexOf(item.uid) < 0) {
          newData.push(item)
        }
      })
      this.callback(newData)
      // this.btnLoad = true
      // const data = await userPowerSave({
      //   folder_id: this.fileInfo.id,
      //   power_data: []
      // })
      // if (data.code === 0) {
      //   this.visible = false
      // }
      // this.btnLoad = false
    },
    setDisabled(item,playLoad) {
      if (item.children) {
        item.children.forEach((items) => {
          if (playLoad.indexOf(items.id) >= 0) {
            items.disabled = true
          } else {
            items.disabled = false
          }
          this.setDisabled(items,playLoad)
        })
      }
    },
    async u_showUserList () {
      this.u_loading = true
      const playLoad = this.columnOptions.map(i => i.uid.toString())
      this.firstplayLoad = JSON.parse(JSON.stringify(playLoad))
      const { data } = await v2departmentMember()
      data.forEach((item) => {
        if (playLoad.indexOf(item.id) >= 0) {
          item.disabled = true
        } else {
          item.disabled = false
        }
        this.setDisabled(item,playLoad)
      })
      this.u_data = data
      const columnOptions = this.columnOptions.map(i => {
        return { ...i, uid: i.uid.toString(), name: i.user_name}
      })
      this.u_dCheckedKeys = playLoad
      this.u_dExpandedKeys = [...this.u_dExpandedKeys, ...playLoad]
      this.u_tableData = columnOptions
      this.u_loading = false
    },
    u_handleCheckChange (node, nodeData) {
      this.nodeData = nodeData
      this.isChangeTree = true
      this.u_tableData = nodeData.checkedNodes.map(i => {
        if (!i.id.startsWith('p')) {
          return { id: i.id, name: i.name, uid: i.id, leaderUid: i.leaderUid, leaderName: i.leaderName, department: i.department, user_name: i.name, avatar: i.avatar }
        }
      }).filter(Boolean)
      console.log(this.u_dCheckedKeys,this.u_dExpandedKeys,nodeData,'u_dCheckedKeys')
    },
    delUserCheck(item) {
      this.$refs.u_tree.getNode(item.id).setChecked(false);
      this.u_tableData = this.u_tableData.filter(items => items.id != item.id)
    }
  },
  created () {
    console.log(this.fileInfo,'fileInfos')
    this.u_showUserList()

  }
}
</script>
<style lang="scss" scoped>
:deep(.el-dialog__body) {
	padding: 0;
}
:deep(.el-dialog__header) {
  padding: 0;
}

:deep(.el-dialog__footer) {
	padding: 20px;
}

.content {
	display: flex;
	height: 500px;

	.left {
		word-break: break-all;
		padding: 20px;
		width: 50%;
		border-right: 1px solid #eaeced;
		// border-top: 1px solid #eaeced;
		border-bottom: 1px solid #eaeced;
		text-overflow: ellipsis;
		overflow: auto;
	}

	.right {
		padding: 20px;
		width: 50%;
		// border-top: 1px solid #eaeced;
		border-bottom: 1px solid #eaeced;
		display: flex;
		flex-direction: column;
	}
}
</style>