import "babel-polyfill"
import Es6Promise from 'es6-promise'
require('es6-promise').polyfill() // 在node或浏览器中支持ES6 与CommonJS
Es6Promise.polyfill()
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import '_a/style/iconfont/iconfont.css'
import '_a/style/iconfont02/iconfont.css'
import file from '_l/file'
import file2 from '_l/file2'
import file3 from '_l/file3'
import { dateFormat } from '_l/format.js'
import { dateFormat2 } from '_l/format2.js'
import { dateFormat3 } from '_l/format3.js'
import config from '@/config/index.js'
// 引入文件操作相关插件
import OperationPlugins from '@/plugins/OperationPlugins.js'
// 引入上传组件
import uploader from 'vue-simple-uploader'

// import Vconsole from 'vconsole'
// let vConsole = new Vconsole()
// Vue.use(vConsole)

Vue.config.productionTip = false
Vue.config.devtools = true
Vue.use(ElementUI)
Vue.use(uploader)
Vue.prototype.$config = config
Vue.prototype[`$file`] = file // 挂载网盘相关公共方法
Vue.prototype[`$file2`] = file2 // 挂载网盘相关公共方法
Vue.prototype[`$file3`] = file3 // 挂载网盘相关公共方法
for (let key in OperationPlugins) {
  Vue.prototype[`$${key}`] = OperationPlugins[key]
}
Vue.filter('dateFormat', dateFormat)
Vue.filter('dateFormat2', dateFormat2)
Vue.filter('dateFormat3', dateFormat3)
export const EventBus = new Vue()

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

// 设置全局离开页面监听
router.afterEach(() => {
  // 清除之前设置的fontSize
  document.documentElement.style.fontSize = '';
});