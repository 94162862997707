import { get, post, postProgress } from './http'
import qs from 'qs'

export const getFileList = data => get(`/v2/companyFile/list?${qs.stringify(data)}`)

export const powerFileList = data => get(`/v2/companyFile/powerFileList?${qs.stringify(data)}`)

export const powerLabel = data => get(`/v2/companyFile/power`, data)

// 新建文件夹
export const newFolder = data => post(`/v2/companyFile/newFolder`, data)

// 修改文件夹
export const changeFolder = data => post(`/v2/companyFile/change`, data)

// 文件夹/文件移动
export const fileMove = data => post(`/v2/companyFile/move`, data)

// 文件夹/文件删除
export const deleteByFalse = data => post(`/v2/companyFile/delete`, data)

// 下载文件
export const downloadFile = (data, onDownloadProgress, cancelToken) => postProgress(`/v2/companyFile/download`, data, onDownloadProgress, cancelToken, true)

//  文件上传预处理
export const filePreprocess = data => post(`/v2/companyFile/preprocess`, data)

// 上传完毕，发起合并文件请求,文件分片上传
export const uploadMerge = data => post(`/v2/companyFile/upload`, data, true)

//回收站列表
export const getRecycleList = data => get(`/v2/companyFile/recycleList`, data)

// 从回收站恢复文件
export const restoreFolderByFalse = data => post(`/v2/companyFile/recycleRestore`, data)

// 日志列表
export const getFileLogList = data => get(`/v2/companyFile/logList`, data)

// 树形文件夹
export const companyfolderTree = data => get(`/v2/companyFile/folderTree`, data)

//回收站文件列表
export const getRecycleFileList = data => get(`/v2/fileStorage/recycle/fileList`, data)

//回收站文件夹列表
export const getRecycleFolderList = data => get(`/v2/fileStorage/recycle/folderList`, data)

// 删除文件到回收站
export const deleteFileByFalse = data => post(`/v2/fileStorage/file/delete`, data)

// 从回收站恢复文件
export const restoreFileByFalse = data => post(`/v2/fileStorage/recycle/fileRestore`, data)

// 获取文件夹
export const getFolderList = data => get(`/v2/fileStorage/folder`, data)

// 删除文件夹
export const deleteFolderByFalse = data => post(`/v2/fileStorage/file/deleteFolder`, data)

// 获取用户组列表
export const getUserGroup = data => get(`/v2/fileStorage/userGroup`, data)

// 新建用户组
export const newUserGroup = data => post(`/v2/fileStorage/newUserGroup`, data, true)

// 修改用户组
export const changeUserGroup = data => post(`/v2/fileStorage/changeUserGroup`, data)

// 权限组-获取用户列表
export const getUserList = data => get(`/v2/getUserList`, data)

// 权限组-获取文件列表
export const getUserFolder = data => get(`/v2/fileStorage/userFolder`, data)

// 权限组-用户绑定用户组
export const saveUserGroup = data => post(`/v2/fileStorage/saveUserGroup`, data)

// 权限组-文件夹绑定用户组
export const saveUserGroupPermission = data => post(`/v2/fileStorage/saveUserGroupPermission`, data)

// 文件夹-文件夹管理员列表
export const getFolderByAdmin = data => get(`/v2/fileStorage/getFolderByAdmin`, data)

// 文件夹-文件夹绑定管理员
export const saveFolderAdmin = data => post(`/v2/fileStorage/saveFolderAdmin`, data)

// 获取预览文件扩展名
export const previewType = data => get(`/v2/fileStorage/previewType?${qs.stringify(data)}`)

//  重命名文件
export const fileRename = data => post(`/v2/fileStorage/file/rename`, data)

//  权限组转交
export const transmitGroup = data => post(`/v2/fileStorage/transmitGroup`, data)

//  获取用户组拥有者
export const userGroupOwner = () => get(`/v2/fileStorage/userGroupOwner`)

// 获取下载任务列表
export const downloadTask = data => get(`/v2/fileStorage/download/list?${qs.stringify(data)}`)

//  新建下载任务
export const newDownloadTask = data => post(`/v2/fileStorage/download/add`, data)

// 获取组织架构部门
export const v2department = () => get(`/v2/department`)

// 获取组织架构人员
export const userdepartmentMember = () => get(`/v2/departmentMember`)

// 权限保存
export const savePower = data => post(`/v2/companyFile/savePower`, data)

//  下载打包文件
export const taskFileDownload = (data, onDownloadProgress, cancelToken) => postProgress(`/v2/fileStorage/download/file`, data, onDownloadProgress, cancelToken, true)

//  导出文件
export const handleFileStorageExport = (data, onDownloadProgress, cancelToken) => postProgress(`/v2/fileStorage/file/folderExport`, data, onDownloadProgress, cancelToken, true)

// 获取组织架构及人员
export const v2departmentMember = () => get(`/v2/fileStorage/folder/departmentMember`)

// 文件夹权限保存
export const userPowerSave = data => post(`/v2/fileStorage/folder/userPowerSave`, data)

//文件夹的用户权限类型
export const powerList = () => get(`/v2/fileStorage/folder/power`)

//文件夹的用户权限列表
export const getUserPower = data => get(`/v2/fileStorage/folder/userPower?${qs.stringify(data)}`)

// 文件夹移动
export const fileMoveFolder = data => post(`/v2/fileStorage/file/moveFolder`, data)

// 文件夹/文件 批量移动
export const fileMoveFolderFile = data => post(`/v2/fileStorage/file/moveFolderFile`, data)

// 文件夹/文件 批量删除
export const deleteFolderFileByFalse = data => post(`/v2/fileStorage/file/deleteFolderFile`, data)

// 文件夹 锁定/解锁
export const lockFolder = data => post(`/v2/fileStorage/file/lockFolder`, data)
